import * as React from "react";
import Layout from "../components/layout/Layout";


// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <h1>404</h1>
    </Layout>
  );
};

export default NotFoundPage;
